// eslint-disable-next-line
import React from "react";
import Header from "./Components/Header/Header";
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Careers from "./Pages/Careers/Careers";
import Footer from "./Components/Footer/Footer";
import Contact from "./Pages/Contact/Contact";

function App() {
  return (
    <>
      <HashRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer></Footer>
      </HashRouter>
    </>
  );
}

export default App;
