import React, { useEffect } from "react";
import "./About.css";
import about1 from "../../Images/about-1.jpg";
import about2 from "../../Images/about-2.jpg";
import ScrollButton from "../../Components/ScrollToTop/ScrollButton";
import { AnimationOnScroll } from "react-animation-on-scroll";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-section">
      <div className="about">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="title">
            <p>About Us</p>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="container">
        <div className="row mt-4 mb-4 align-items-center">
          <div className="col-md-5 mb-5">
            <div className="about-info">
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
                <h2>Who We Are</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={150}>
                <p>
                  In this Digital World and also being Design Development
                  Company for Web and Mobile, we have provided our clients with
                  best Web and Mobile app's Design and Solutions. We at Pearl's
                  IT Solutions, have a motto of Customer satisfaction as our
                  Priority.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 mb-5">
            <AnimationOnScroll animateIn="animate__zoomIn">
              <div className="image">
                <img src={about1} alt="about" className="img-fluid" />
              </div>
            </AnimationOnScroll>
          </div>
          <div className="col-md-6">
            <AnimationOnScroll animateIn="animate__zoomIn">
              <div className="image">
                <img src={about2} alt="about" className="img-fluid" />
              </div>
            </AnimationOnScroll>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="about-info">
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
                <h2>Our Vision and Mission</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={150}>
                <p>
                  Our Vision is to provide Best-In-Class Development to our
                  Clients and met their expectations with innovative and
                  excellence team members. Our Mission is to create products
                  that are based on innovation that will create different effect
                  in the Digital World.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
      <ScrollButton />
    </div>
  );
};

export default About;
