import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { FaLaptopCode, FaMobileAlt } from "react-icons/fa";
import "./service.css";

const Service = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="services_title">
          <h1>Our Services</h1>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="icon-wrapper">
                <i className="fas">
                  <FaLaptopCode />
                </i>
              </div>
              <AnimationOnScroll animateIn="animate__zoomIn" delay={100}>
                <h3>Web development</h3>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
                <p>
                  Speed up your business profitability with our web applications
                  and meet the unique requirements of your B2B or B2C business
                  quickly and efficiently.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="icon-wrapper">
                <i className="fas">
                  <FaMobileAlt />
                </i>
              </div>
              <AnimationOnScroll animateIn="animate__zoomIn" delay={150}>
                <h3>Mobile App development</h3>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={150}>
                <p>
                  We provide the Design and Development of any custom mobile
                  app. Also we provide proper services for the app for it's
                  better performance and results.
                </p>
              </AnimationOnScroll>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="icon-wrapper">
                <i className="fas">
                  <FaLaptopCode />
                </i>
              </div>
              <AnimationOnScroll animateIn="animate__zoomIn" delay={200}>
                <h3>Custom Software development</h3>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={200}>
                <p>
                  Translate unique client requirements into the custom software
                  solutions with best quality and advanced technologies.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
